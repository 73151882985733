// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1739185044130.939";
}
// REMIX HMR END

import { redirect } from "@remix-run/node";
import { Outlet } from "@remix-run/react";
import { useTheme } from "next-themes";
import medicare from "~/assets/illustrations/medicare.svg";
import logoBlackText from "~/assets/images/logo-black-text.png";
import logoWhiteText from "~/assets/images/logo.png";
import { getAuthSession } from "~/auth-session";
const AdminLogin = () => {
  _s();
  const {
    theme
  } = useTheme();
  return <div className='flex gap-4 h-screen dark:bg-slate-950'>
            {/* login form */}
            <div className='w-full md:w-1/2 flex justify-center'>
                <div className='w-full md:w-1/2 h-full flex flex-col justify-between py-3 px-4'>
                    <div>
                        <img src={theme === "light" ? logoBlackText : logoWhiteText} alt='logo' className='w-32 md:w-52' />
                        <h2 className='md:hidden font-montserrat font-bold text-amber-300  text-3xl'>
                            Med Treatment App
                        </h2>
                    </div>

                    <div className='flex flex-col gap-10 flex-1 md:flex-none pt-28 md:pt-0'>
                        <div className='flex flex-col gap-1'>
                            <h3 className='font-montserrat font-bold text-3xl md:text-5xl text-slate-800 dark:text-white'>
                                Sign In
                            </h3>
                            <p className='font-nunito text-xs md:text-base text-slate-400 dark:text-slate-200'>
                                Login to your dashboard
                            </p>
                        </div>

                        <Outlet />
                    </div>

                    <p className='text-sm font-montserrat text-slate-400'>
                        &copy; {new Date().getFullYear()} Adamus IT | All Rights
                        Reserved
                    </p>
                </div>
            </div>

            {/* illustration */}
            <div className='hidden md:flex-1 flex-col gap-4 items-center justify-center md:flex bg-slate-400/5 dark:bg-slate-900 h-full rounded-bl-[12rem]'>
                <img src={medicare} alt='medicare illustration' className='w-2/3 animate-bounce-slow' />
                <h1 className='font-montserrat font-extrabold text-5xl text-blue-600 text-center'>
                    Med Treatment App
                </h1>

                {/* <ExcelReader /> */}
            </div>
        </div>;
};
_s(AdminLogin, "JkSxfi8+JQlqgIgDOc3wQN+nVIw=", false, function () {
  return [useTheme];
});
_c = AdminLogin;
export default AdminLogin;
export const loader = async ({
  request
}) => {
  const authSession = await getAuthSession(request.headers.get("Cookie"));
  const token = authSession.get("token");
  const user = authSession.get("user");
  if (token) {
    return redirect(`/${user?.role}`);
  } else {
    return null;
  }
};
var _c;
$RefreshReg$(_c, "AdminLogin");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;